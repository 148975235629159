






















import { Component, Vue, Prop } from 'vue-property-decorator'
import detailHeader from '@/modules/common/components/detail-header.vue'
import navTab from '@/modules/common/components/nav-tab.vue'
import ueditorDisplay from '@/modules/common/components/ueditor-display/index.vue'
import UeditorCut from '@/modules/common/components/ueditor-cut.vue'
import * as model from '../models'

@Component({
  components: {
    detailHeader,
    navTab,
    ueditorDisplay,
    UeditorCut
  }
})
export default class ContentBeforeBuy extends Vue {
  @Prop({ default: () => { return {} } })
  contentInfo!: model.ContentInfo

  @Prop({ default: () => { return {} } })
  pintuanDetail!: any

  showFreeArticleContent: boolean = false
  freePercent: number = 0.01
  tabList: Array<object> = [
    { name: '内容介绍', value: 'introduce' }
  ]
  activeTab: string = 'introduce'
  created() {
  }
  mounted() {
    this.getFreeArticleInfo()
    if (this.showFreeArticleContent) {
      this.tabList.splice(1, 1, { name: '试看', value: 'free' })
    }
  }
  getFreeArticleInfo() {
    let detail = this.contentInfo
    let freePercent = 0.01
    let showFreeArticleContent = false

    if (detail.type == 'article' && detail.preview_config && detail.preview_config.preview_type) {
      showFreeArticleContent = true
      switch (detail.preview_config.preview_type) {
        // 整篇试看：观看原全篇内容
        case 'total':
          freePercent = 1
          break
        // 部分试看：观看原部分内容
        case 'partial':
          freePercent = detail.preview_config.preview_percent ? Number(detail.preview_config.preview_percent) : 0.01
          break
        default:
          freePercent = 0.01
          break
      }
    } else {
      showFreeArticleContent = false
      freePercent = 1
    }
    this.showFreeArticleContent = showFreeArticleContent
    this.freePercent = freePercent
  }
  toggleHandler(value: string) {
    this.activeTab = value
  }
}
