








import { Component, Vue, Watch } from 'vue-property-decorator'
import contentBeforeBuy from '@/modules/content/components/content-before-buy.vue'
import contentAfterBuy from '@/modules/content/components/content-after-buy.vue'
import LiveAfterBuy from '@/modules/content/components/live-after-buy.vue'
import * as api from '../api'
import * as model from '../models'
import { utils } from '../../../utils/utils'
import { ApiInfo } from '../../common/models'
import { getPintuanDetail } from '@/modules/common/api/index.ts'
import { PintuanDetail } from '@/modules/common/models/index.ts'
import { checkLogin } from '@/utils/user'

@Component({
  components: {
    contentBeforeBuy,
    LiveAfterBuy,
    contentAfterBuy
  },
  metaInfo() {
    const meta: any = {}
    const title = (this as any).contentInfo.title
    if (title) meta.title = title
    return meta
  }
})
export default class ContentDetail extends Vue {
  isLoading: boolean = true
  errorObj: any = null
  contentInfo: model.ContentInfo = {
    is_subscribe: 0,
    brief: '',
    content: '',
    content_id: '',
    indexpic: '',
    price: '',
    original_price: '',
    title: '',
    type: '',
    payment_type: 0,
    subscribe: 0,
    comment_count: 0,
    view_count: 0,
    create_time: '',
    up_time: '',
    update_time: '',
    fightgroup: '',
    limit_price: '',
    limit_start: '',
    limit_end: '',
    limit_purshase: {},
    id: '',
    skus: []
  }
  pintuanDetail: any = {}
  created() {

  }
  mounted() {
    this.getFreeContentDetail()
  }

  @Watch('$route')
  on$routeChanged() {
    this.getFreeContentDetail()
  }
  get isKeyGoods() {
    const contentInfo = this.contentInfo
    if (contentInfo.payment_type == 5) {
      return !contentInfo.is_subscribe && contentInfo.lecturer !== 1
    } else {
      return false
    }
  }
  get isToAfterBuy() {
    const contentInfo = this.contentInfo
    let isMemberFree = Number(contentInfo.price) === 0 && Number(contentInfo.cost_price) > 0 && !this.isKeyGoods
    let isContentFree = Number(contentInfo.price) === 0 && !this.isKeyGoods
    let isToAfterBuy = false
    if (isContentFree || isMemberFree || contentInfo.is_subscribe) {
      isToAfterBuy = true
    } else if (contentInfo.type === 'live' && contentInfo.lecturer) {
      isToAfterBuy = true
    } else {
      isToAfterBuy = false
    }
    return isToAfterBuy
  }
  getFreeContentDetail() {
    this.isLoading = true
    api.getFreeContentDetail(this.$route.params.id).then(
      (res: any) => {
        if (res.error) {
          this.errorObj = {
            html: res.message || '服务器繁忙'
          }
          this.isLoading = false
        } else {
          this.contentInfo = res.data
          if (this.isToAfterBuy) {
            this.getContentDetail()
          } else {
            this.isLoading = false
          }
          if (res.data.fightgroup) {
            this.getPintuanDetail()
          }
        }
      }
    ).catch(res => {
      this.isLoading = false
      console.error('getFreeContentDetail =', res)
    })
  }
  getContentDetail() {
    this.isLoading = true
    api.getContentDetail(this.$route.params.id).then((res) => {
      this.contentInfo = res.data
      this.isLoading = false
    }).catch(res => {
      this.isLoading = false
      console.error('getContentDetail =', res)
    })
  }
  getPintuanDetail() {
    // if (!checkLogin()) return
    let id = this.contentInfo.fightgroup
    if (id) {
      getPintuanDetail(id).then((res): void => {
        this.pintuanDetail = res
      })
    }
  }
}
