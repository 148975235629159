





































import { Component, Vue, Prop } from 'vue-property-decorator'
import detailHeader from '@/modules/common/components/detail-header.vue'
import navTab from '@/modules/common/components/nav-tab.vue'
import CountDown from '@/modules/common/components/count-down.vue'
import ueditorDisplay from '@/modules/common/components/ueditor-display/index.vue'
import * as model from '../models'
import enterCheck from '@/modules/studio/pages/detail/enterCheck'

  @Component({
    components: {
      detailHeader,
      CountDown,
      navTab,
      ueditorDisplay
    }
  })
export default class ContentBeforeBuy extends Vue {
    @Prop({ default: {} }) contentInfo!: model.ContentInfo

    tabList: Array<object> = [
      { name: '内容介绍', value: 'introduce' }
    ]
    activeTab: string = 'introduce'
    created() {
    }
    mounted() {
    }
    toStudio() {
      if (enterCheck(this.contentInfo, this)) {
        this.$router.push({
          name: 'studio',
          params: {
            id: this.contentInfo.content_id
          }
        })
      }
    }
    end() {
    }
}
