<template>
  <div>
    <slot name="time" :time="time" v-if="time.valid"></slot>
    <slot name="end" v-else></slot>
  </div>
</template>
<script>
export default {
  props: ['endTime', 'sysTime'], // 日期字符串 或 ms
  data () {
    return {
      localTime: new Date() / 1000,
      offset: this.count,
      timer: null
    }
  },
  mounted () {
    this.localTime = this.sysTime || Math.ceil(new Date() / 1000) // 服务器时间
    this.timer = setInterval(e => {
      this.localTime += 1
    }, 1000)
  },
  beforeDestroy () {
    clearInterval(this.timer)
  },
  computed: {
    time () {
      let endTime = this.endTime
      return this.counterTime(endTime, this.localTime)
    }
  },
  methods: {
    counterTime (endTime, localTime) {
      if (isNaN(endTime)) {
        endTime = endTime.replace(/-/g, '/')
      }
      let now = localTime * 1000
      let end = typeof endTime === 'number' ? endTime : new Date(endTime)
      let range = (end - now) / 1000
      let counter = {}
      if (range < 0) {
        range = 0 - range
        counter.valid = false
      } else {
        counter.valid = true
      }
      counter.day = this.operateTime(Math.floor(range / (24 * 60 * 60)))
      counter.hour = this.operateTime(Math.floor(range % (24 * 60 * 60) / (60 * 60)))
      counter.min = this.operateTime(Math.floor(range % (24 * 60 * 60) % (60 * 60) / 60))
      counter.sec = this.operateTime(Math.floor(range % (24 * 60 * 60) % (60 * 60) % 60))
      return counter
    },
    operateTime (time) {
      return time < 10 ? '0' + time : time
    }
  },
  watch: {
    'time.valid' (nVal) {
      if (!nVal) {
        this.$emit('end')
        clearInterval(this.timer)
      }
    }
  },
  destroyed () {
    clearInterval(this.timer)
  }
}
</script>
